<template>
  <b-row>
    <b-col
      cols="12"
      xl="12"
      md="12"
    >
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>Temario (contenido)</h5>
        </b-card-header>
        <b-card-body>
          <div v-if="loading">
            Loading...
          </div>
          <div v-else-if="error">
            Error: {{ error.message }}
          </div>
          <div v-else-if="cursoTemario">
            <!-- Row Loop -->
            <b-row>

              <!-- Item Name -->
              <b-col
                md="12"
                class="todo-task-list-wrapper list-group scroll-area"
              >
                <draggable
                  v-model="cursoTemario.temario"
                  handle=".draggable-task-handle"
                  tag="ul"
                >
                  <b-list-group-item
                    v-for="item in cursoTemario.temario"
                    :key="item.id"
                    tag="li"
                  >
                    <feather-icon
                      icon="MoreVerticalIcon"
                      class="draggable-task-handle d-inline"
                    />
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-warning"
                      size="sm"
                      class="btn-icon rounded-circle"
                      @click="handleTaskClick(item)"
                    >
                      <feather-icon
                        icon="EditIcon"
                      />
                    </b-button>
                    <strong> {{ item.titulo }}</strong>
                    <!--small class="text-nowrap text-muted mr-1">{{ formatDate(sub.dueDate, { month: 'short', day: 'numeric'}) }}</small-->
                    <draggable
                      v-model="item.sub_temas"
                      tag="ul"
                      class="cursor-move"
                    >
                      <transition-group
                        type="transition"
                        name="flip-list"
                      >
                        <b-list-group-item
                          v-for="(sub, index) in item.sub_temas"
                          :key="index"
                          tag="li"
                          @click="handleTaskClickSt(sub, item)"
                        >
                          <feather-icon
                            icon="MoreVerticalIcon"
                            class="draggable-task-handle d-inline"
                          />
                          <strong> {{ sub.titulo }}</strong>
                          <!--small class="text-nowrap text-muted mr-1">{{ formatDate(sub.dueDate, { month: 'short', day: 'numeric'}) }}</small-->
                        </b-list-group-item>
                      </transition-group>
                    </draggable>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="flat-primary"
                      size="sm"
                      block
                      @click="abrirNuevoSubTema(item)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                      <span>Agregar Nuevo SubTema</span>
                    </b-button>
                  </b-list-group-item>
                </draggable>

              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  block
                  @click="isTaskHandlerSidebarActive=true"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Nuevo Tema</span>
                </b-button>
                <hr>
              </b-col>
            </b-row>
            <!-- Task Handler -->
            <todo-task-handler-sidebar
              v-model="isTaskHandlerSidebarActive"
              :task="task"
              :clear-task-data="clearTaskData"
              @remove-task="removeTask"
              @add-task="addTask"
              @update-task="updateTask"
            />
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  /* BCardText, */ BCardHeader, BListGroupItem,
  BCardBody, BButton, /* BForm, BFormInvalidFeedback, BFormGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BTable, BSpinner, */
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref } from '@vue/composition-api'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import Ripple from 'vue-ripple-directive'
import { formatDate, avatarText } from '@core/utils/filter'
import FIND_CURSO_VERSION_TEMARIO_ID from '../../../../graphql/cursos/curso/version/GetTemarioCursoVersion.gql'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import ADD_TEMA_CURSOVERSION from '../../../../graphql/cursos/curso/version/AddTemaCursoVersion.gql'
import ADD_SUBTEMA_CURSOVERSION from '../../../../graphql/cursos/curso/version/AddSubTemaCursoVersion.gql'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BCardText,
    BCardHeader,
    BCardBody,
    BButton,
    /* BFormCheckbox,
    BAvatar,
    BButton, */
    // BForm,
    // BFormInvalidFeedback,
    // BFormGroup,
    // BFormInput,
    // BInputGroupPrepend,
    // BInputGroupAppend,
    // BInputGroup,
    // BTable,
    // BSpinner,
    draggable,
    BListGroupItem,
    // BBadge,
    // VuePerfectScrollbar,

    TodoTaskHandlerSidebar,
  },
  directives: {
    Ripple,
  },
  setup() {
    // Use toast
    const toast = useToast()

    const blankTask = {
      cursoVersionId: router.currentRoute.params.id,
      titulo: '',
      // descripcion: '',
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const {
      loading, result, error, refetch,
    } = useQuery(FIND_CURSO_VERSION_TEMARIO_ID, () => ({ cursoVersionId: router.currentRoute.params.id }))
    const cursoTemario = useResult(result, null, data => data.getCursoVersionEditTemarioById)

    const { mutate: guardarTema, onDone, onError } = useMutation(ADD_TEMA_CURSOVERSION, () => ({
      variables: task.value,
    }))

    const { mutate: guardarSubtema, ...rest } = useMutation(ADD_SUBTEMA_CURSOVERSION, () => ({
      variables: task.value,
    }))
    rest.onDone(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Curso Registrado',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      refetch()
    })

    const addTask = val => {
      console.log('ADD TASK', val)
      if (task.value.tema_id) {
        // task.value.titulo = val.titulo
        task.value = val
        // task.value.
        // agrega un subtema a tema (guarda un subtema)
        guardarSubtema()
      } else {
        // guarda un nuevo Tema
        console.log(val)
        task.value = val
        guardarTema()
      }
      /* store.dispatch('app-todo/addTask', val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        }) */
    }

    const removeTask = () => {
      /* store.dispatch('app-todo/removeTask', { id: task.value.id })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        }) */
    }
    const updateTask = taskData => {
      console.log(taskData)
      /* store.dispatch('app-todo/updateTask', { task: taskData })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        }) */
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const isTaskHandlerSidebarActive = ref(false)

    const handleTaskClick = taskData => {
      console.log(taskData)
      let dato
      if (taskData.sub_temas) {
        const { id, titulo, descripcion } = taskData
        dato = {
          id,
          titulo,
          descripcion,
          sub: false,
        }
      } else {
        const {
          id, titulo, video, tema_id,
        } = taskData
        dato = {
          id,
          tema_id,
          titulo,
          ...video,
          sub: true,
        }
      }
      console.log(dato)
      task.value = dato
      isTaskHandlerSidebarActive.value = true
    }

    const handleTaskClickSt = (subTema, item) => {
      const dato = {
        ...subTema,
        tema_id: item.id,
      }
      // subTema = dato
      handleTaskClick(dato)
    }

    const abrirNuevoSubTema = tema => {
      // task.value.tema_id = tema.id
      // task.value.prueba = 'Pueba de prueba'
      console.log('TEMAAA:  ', task.value)
      const dato = {
        ...task.value,
        tema_id: tema.id,
        url: '',
        detalle_video: '',
        sub: true,
      }
      task.value = dato
      isTaskHandlerSidebarActive.value = true
    }
    onDone(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Curso Registrado',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      refetch()
    })

    onError(err => {
      toast({
        component: ToastificationContent,
        props: {
          title: err.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    return {
      task,
      clearTaskData,
      cursoTemario,
      loading,
      error,
      perfectScrollbarSettings,
      formatDate,
      avatarText,
      handleTaskClick,
      handleTaskClickSt,
      addTask,
      removeTask,
      updateTask,
      abrirNuevoSubTema,
      isTaskHandlerSidebarActive,
      refetch,
    }
  },
}
</script>

<style>
.list-group-item {
  transition: all 1s
}
</style>
