<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Datos (anuncio)
      </h5>
      <b-badge variant="light-primary">
        {{ cursoVersionData.publicado?'Publicado':'Borrador' }}
      </b-badge>
      <small class="text-muted w-100">
        <strong>creado en:</strong> {{ formatFecha(cursoVersionData.createdAt) }}
      </small>
    </b-card-header>

    <b-card-body>
      <div class="mt-2 mt-xl-0 w-100">
          <div>
            <div class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Slug:</span>
              <small class="pb-50 text-capitalize">{{ cursoVersionData.slug }}</small>
            </div>
          </div>
          <div>
            <div class="pb-50">
              <feather-icon
                icon="CheckIcon"
              />
              <span class="font-weight-bold">Detalle: </span>
              {{ cursoVersionData.detalle }}
            </div>
          </div>
          <div>
            <div class="pt-50">
              <feather-icon
                icon="StarIcon"
              />
              <span class="font-weight-bold">Resumen:</span>
              {{ cursoVersionData.resumen }}
            </div>
          </div>
          <div>
            <div class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Doc:</span>
              <span v-for="(doc, index) in cursoVersionData.docentes" :key="index">
                {{ `${doc.nombres} ${doc.paterno} - ${doc.pais.nombre}.` }}
              </span>
            </div>
          </div>
        </div>
      <ul class="list-unstyled my-1">
        <li v-if="cursoVersionData.fecha_inicio">
          <span class="align-middle">
            <strong>F. Inicio:</strong> {{ new Date(cursoVersionData.fecha_inicio).toLocaleDateString() }}
          </span>
        </li>
        <li class="my-25" v-if="cursoVersionData.fecha_fin">
          <span class="align-middle">
            <strong>F. Fin:</strong> {{ new Date(cursoVersionData.fecha_fin).toLocaleDateString() }}
          </span>
        </li>
        <li v-if="cursoVersionData.duracion">
          <span class="align-middle">
            <strong>Duración:</strong> {{ cursoVersionData.duracion }}
          </span>
        </li>
        <li v-if="cursoVersionData.dias.length > 0">
          <span class="align-middle">
            <strong>Dias:</strong>
            <span v-for="(dia, index) in cursoVersionData.dias" :key="index">
              {{ dia }},
            </span>
          </span>
        </li>
        <li v-if="cursoVersionData.cupos">
          <span class="align-middle">
            <strong>Cupos:</strong> {{ cursoVersionData.cupos }}
          </span>
        </li>
        <li v-if="cursoVersionData.carga_horaria">
          <span class="align-middle">
            <strong>Carga Horas:</strong> {{ cursoVersionData.carga_horaria }}
          </span>
        </li>
        <li v-if="cursoVersionData.descuento">
          <span class="align-middle">
            <strong>Desc Gral:</strong> {{ cursoVersionData.descuento }}
          </span>
        </li>
        <li v-if="cursoVersionData.formato">
          <span class="align-middle">
            <strong>Formato:</strong> {{ cursoVersionData.formato }}
          </span>
        </li>
        <li v-if="cursoVersionData.asesoramiento">
          <span class="align-middle">
            <strong>Asesoramiento:</strong> {{ cursoVersionData.asesoramiento }}
          </span>
        </li>
        <li v-if="cursoVersionData.certificado">
          <span class="align-middle">
            <strong>Certificado:</strong> {{ cursoVersionData.certificado }}
          </span>
        </li>
        <li>
          <span class="align-middle">
            <strong>metodos Pago:</strong>
            <ul>
              <li v-for="(metodo, index) in cursoVersionData.metodo_pago" :key="index">
                <p>{{ metodo.nombre }}</p>
              </li>
            </ul>
          </span>
        </li>
        <li>
          <span class="align-middle">
            <strong>Horarios:</strong>
            <ul>
              <li v-for="(hora, index) in cursoVersionData.hora" :key="index">
                <p>{{ hora.pais.nombre }}: {{ hora.hora }}</p>
              </li>
            </ul>
          </span>
        </li>
      </ul>
      <hr>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">
            <strong>Habilidades:</strong>
            <ul>
              <li v-for="(h, index) in cursoVersionData.habilidades" :key="index">
                <p>{{ h }}</p>
              </li>
            </ul>
          </span>
        </li>
        <li>
          <span class="align-middle">
            <strong>Requisitos:</strong>
            <ul>
              <li v-for="(r, index) in cursoVersionData.requisitos" :key="index">
                <p>{{ r }}</p>
              </li>
            </ul>
          </span>
        </li>
        <li>
          <span class="align-middle">
            <table>
              <tr>
                <td><strong>Tags: </strong></td>
                <td>
                  <b-badge class="badge badge-info mr-1" v-for="(t, index) in cursoVersionData.tags" :key="index">{{ t.nombre }}</b-badge>
                </td>
              </tr>
            </table>
          </span>
        </li>
        <li>
          <span class="align-middle">
            <strong>Beneficios:</strong>
            <ul>
              <li v-for="(b, index) in cursoVersionData.beneficios" :key="index">
                <p>{{ b.titulo }}</p>
              </li>
            </ul>
          </span>
        </li>
        <li>
          <span class="align-middle">
            <strong>Preg y Resp:</strong>
            <ul>
              <li v-for="(f, index) in cursoVersionData.faqs" :key="index">
                <p>{{ f.pregunta }}</p>
              </li>
            </ul>
          </span>
        </li>
      </ul>
      <!--b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Upgrade Plan
      </b-button-->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    // BButton,
  },
  props: {
    cursoVersionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const formatFecha = fecha => {
      const date = new Date(fecha)

      return date.toLocaleString()
    }

    return {
      formatFecha,
    }
  },
}
</script>

<style>

</style>
