<template>
  <b-card
    no-body
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Descripción General
      </h5>
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          xl="12"
        >
          <div v-html="cursoVersionData.descripcion_general" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardHeader, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BRow, BCol, BCardHeader, BCardBody,
  },
  props: {
    cursoVersionData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
