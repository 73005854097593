<template>
  <b-card>
    <h5 class="mb-0">
      Imagenes Presentación
    </h5>
    <b-row>
      <!-- latest photo loop -->
      <b-col
        v-for="(img, index) in cursoVersionData.imagen_presentacion"
        :key="index"
        md="4"
        cols="6"
        class="profile-latest-img"
      >
        <b-link>
          <b-img
            fluid
            rounded
            :src="(cursoVersionData.imagen_presentacion[0])?`https://back.blumbit.net/api/public/${img.url}`:''"
            alt="detalle"
          />
        </b-link>
      </b-col>
      <!-- latest photo loop -->
      <b-col
          cols="12"
          xl="12"
        >
          <h5 class="mt-1">
            Vídeos Presentación
          </h5>
          <div
            v-for="(vid, index) in cursoVersionData.video_presentacion"
            :key="index">
            <a :href="vid.url" target="_blank">{{ vid.titulo }}</a>
          </div>
        </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BLink, BImg,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BRow, BCol, BLink, BImg,
  },
  props: {
    cursoVersionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>

</style>
