<template>
<div>
  <b-row>
        <b-col
          cols="12"
          xl="8"
          lg="7"
          md="7"
        >
          <CursoVersionGrabadoVivo v-if="cursoVersionData.curso_grabado_vivo" /> <!-- curso grabado en vivo -->
          <TemarioCursoVersion v-else /> <!-- curso -->
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="4"
          lg="5"
        >
          <user-view-user-plan-card :curso-version-data="cursoVersionData" />
          <curso-version-media-card :curso-version-data="cursoVersionData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <curso-version-descripcion-card :curso-version-data="cursoVersionData" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <TemarioCursoVersion v-if="cursoVersionData.curso_grabado_vivo == true" />
        </b-col>
      </b-row>
</div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import CursoVersionDescripcionCard from './CursoVersionDescripcionCard.vue'
import CursoVersionMediaCard from './CursoVersionMediaCard.vue'
import TemarioCursoVersion from '../TemarioCursoVersion.vue'
import CursoVersionGrabadoVivo from '../CursoVersionGrabadoVivo.vue'

export default {
  components: {
    BRow,
    BCol,

    UserViewUserPlanCard,
    TemarioCursoVersion,
    CursoVersionGrabadoVivo,
    CursoVersionDescripcionCard,
    CursoVersionMediaCard,
  },
  props: {
    cursoVersionData: {
      type: Object,
      required: true,
    },
  },

}
</script>
